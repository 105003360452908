import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import axios from 'axios'
import Web3Modal from 'web3modal'
import { Row, Col } from 'react-bootstrap'

import AssetCard from './AssetCard'

import { AppParams } from '../config'

import Market from '../artifacts/contracts/NFTMarket.sol/NFTMarket.json'
import NFT from '../artifacts/contracts/NFT.sol/NFT.json'

const CreatorDashboard = () => {
  const [nfts, setNfts] = useState([])
  const [sold, setSold] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()

    const marketContract = new ethers.Contract(
      AppParams.MARKET_ADDRESS,
      Market.abi,
      signer
    )
    const tokenContract = new ethers.Contract(AppParams.MAIN_TOKEN_CONTRACT_ADDRESS, NFT.abi, provider)
    const data = await marketContract.fetchItemsCreated()

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId)
        const meta = await axios.get(tokenUri)
        let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          sold: i.sold,
          image: meta.data.image,
        }
        return item
      })
    )
    /* create a filtered array of items that have been sold */
    const soldItems = items.filter((i) => i.sold)
    setSold(soldItems)
    setNfts(items)
    setLoadingState('loaded')
  }
  if (loadingState === 'loaded' && !nfts.length)
    return <h1 className='py-10 px-20 text-3xl'>No assets created</h1>
  return (
    <div>
      <div className='p-4'>
        <h2 className='text-2xl py-2'>Items Created</h2>

        {/* {nfts.map((nft, i) => (
          <div key={i}>
            <img alt='MED' src={nft.image} className='rounded' />
            <div className='p-4 bg-black'>
              <p className='text-2xl font-bold text-white'>
                Price - {nft.price} Eth
              </p>
            </div>
          </div>
        ))} */}
        <Row>
          {nfts.map((nft, index) => (
            <Col key={index} sm={12} md={6} lg={4} xl={3}>
              <AssetCard nft={nft} />
            </Col>
          ))}
        </Row>
      </div>
      <div className='px-4'>
        {Boolean(sold.length) && (
          <div>
            <h2 className='text-2xl py-2'>Items sold</h2>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 pt-4'>
              {/* {sold.map((nft, i) => (
                <div
                  key={i}
                  className='border shadow rounded-xl overflow-hidden'
                >
                  <img alt='MED' src={nft.image} className='rounded' />
                  <div className='p-4 bg-black'>
                    <p className='text-2xl font-bold text-white'>
                      Price - {nft.price} Eth
                    </p>
                  </div>
                </div>
              ))} */}
              <Row>
                {sold.map((nft, index) => (
                  <Col key={index} sm={12} md={6} lg={4} xl={3}>
                    <AssetCard nft={nft} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreatorDashboard
