import React, { useState, useEffect } from 'react'
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  DropdownButton,
  Dropdown,
  Modal,
  CloseButton,
} from 'react-bootstrap'
import axios from 'axios'

import { LinkContainer } from 'react-router-bootstrap'

import { shortenAddress } from '../utils/shortenAddress'

import { ethers } from 'ethers'
import { AppParams, networks } from '../config'

import { useRecoilState } from 'recoil'
import { accountState, avaxValueUsd, referralAddressState } from '../services/atoms'
import NotificationBar from './NotificationBar'
import { useNavigate } from 'react-router-dom'

const { ethereum } = window

const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!ethereum) throw new Error('No crypto wallet found')
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          ...networks[networkName],
        },
      ],
    })
  } catch (err) {
    setError(err.message)
  }
}

const Header = () => {
  const [account, setAccount] = useRecoilState(accountState)
  const [referralAddress, setReferralAddress] = useRecoilState(referralAddressState)

  const [error, setError] = useState()

  const [isConnecting, setIsConnecting] = useState(false)
  const [isAvalanche, setIsAvalanche] = useState(false)
  const [provider, setProvider] = useState(ethereum)
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(true)
  const [isMetamaskAlertOpened, setIsMetamaskAlertOpened] = useState(false)

  const navigate = useNavigate()

  const connectWallet = async () => {
    localStorage.setItem('loggedOut', 'false')
    try {
      if (!ethereum) {
        setIsMetamaskAlertOpened(true)
        return
        //return alert('Please install metamask')
      }

      // Loading...
      setIsConnecting(true)
      // Get all the accounts and the user will be able to choose one.
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

      // Loading...
      setIsConnecting(false)
      setAccount(accounts[0])
      // Test provider avalanche
      console.log(provider.chainId)
      if (provider.chainId !== AppParams.AppChainId) {
        await handleNetworkSwitch(AppParams.AppChainId == '0xa86a' ? 'etheurem' : 'goerli')
        // setIsAvalanche(false)
      } else {
        setIsAvalanche(true)
      }
      console.log('isAvalanche: ', isAvalanche)

      ethereum.on('accountsChanged', function (accounts) {
        console.log('accountChaned', accounts)
        setAccount(accounts[0])
      })
    } catch (error) {
      console.log(error)
      setIsConnecting(false)
      throw new Error('No ethereum object.')
    }
  }

  const checkIfWalletIsConnected = async () => {
    try {
      //if (!ethereum) return alert('Please install metamask')

      const accounts = await ethereum.request({ method: 'eth_accounts' })

      if (accounts.length) {
        setAccount(accounts[0])
      } else {
        console.log('No Accounts found')
      }
    } catch (error) {
      console.log(error)
      throw new Error('No ethereum object.')
    }
  }

  console.log('current account: ', account)

  // Control current Network via the API exposed by Metamask! (A nice user experience perspective)
  // If for example the user hasn't the BSC Mainnet in his wallet, then it will ask him
  // to add it inside his wallet
  const handleNetworkSwitch = async (networkName) => {
    setError()
    await changeNetwork({ networkName, setError })
  }
  const handleNetworkDisconnect = async () => {
    setAccount('')
    localStorage.setItem('loggedOut', 'true')
    navigate('/')
  }

  const networkChanged = (chainId) => {
    window.location.reload()
    console.log({ chainId })
    // Test Avalanche Network
    // if (chainId === '0xa86a') {
    //   setIsAvalanche(true)
    // } else {
    //   setIsAvalanche(false)
    // }
  }

  const detectProvider = () => {
    let provider
    if (window.ethereum) {
      provider = window.ethereum
    } else if (window.web3) {
      provider = window.web3.currentProvider
    } else {
      console.warn('No Ethereum browser detected! Check out Metamask')
    }
    //console.log('provider: ', provider.networkVersion)
    return provider
  }

  const [avaxValue, setAvaxValue] = useRecoilState(avaxValueUsd)

  const fetchAvaxValue = async () => {
    axios.get('https://api.traderjoexyz.com/priceusd/avax').then((result) => {
      console.log('result.data: ', result.data)
      setAvaxValue(ethers.utils.formatEther(result.data + ''))
    })
  }

  useEffect(() => {

    setReferralAddress(localStorage.getItem("referralAddress"))

    // fetchAvaxValue
    fetchAvaxValue()
    // Detect Provider
    if (localStorage.getItem('loggedOut') == 'true' || localStorage.getItem('loggedOut') == null) return

    setProvider(detectProvider())
    detectProvider()
    // If not avalanche network version
    // if (provider.networkVersion !== '43114') {
    // }
    connectWallet()

    if (
      (localStorage.getItem('loggedOut') == 'true' ||
        localStorage.getItem('loggedOut') == null) &&
      window.location.pathname != '/'
    ) {
      navigate('/')
    }

    checkIfWalletIsConnected()
    try {
      ethereum.on('chainChanged', networkChanged)
    } catch (e) {
      console.error(e)
    }

    return () => {
      ethereum.removeListener('chainChanged', networkChanged)
    }
  }, [])

  // The second useEffect will run each time the provider is updated
  useEffect(() => {
    if (provider) {
      if (provider !== window.ethereum) {
        console.error(
          'Not window.ethereum provider. Do you have mutiple wallets installed ?'
        )
      }
      // If we have a provider that means that metamask is well installed
      setIsMetamaskInstalled(true)
    }
  }, [provider])

  // useEffect(() => {
  //   checkIfWalletIsConnected()
  // }, [])

  return (
    <header>
      <Navbar variant='dark' expand='lg' collapseOnSelect className="pt-2 pb-2">
        <Container
          fluid
          // fluid
        >
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src='/images/logo-ant.png' height='50' alt='logoopen2be' />
            </Navbar.Brand>
          </LinkContainer>
          <div className='element-hide-l'>
            <NotificationBar></NotificationBar>
          </div>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav className='me-auto my-2 my-lg-0' navbarScroll>
              {/* <LinkContainer to='/'>
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>  */}

              {/* {!isMetamaskInstalled && (
                  <LinkContainer to='https://metamask.io/download/'>
                    <Nav.Link>Install Metamask</Nav.Link>
                  </LinkContainer>
                )} */}
              <LinkContainer to='/nfts'>
                <Nav.Link>Marketplace</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to='/create-item'>
                <Nav.Link>Sell Digital Asset</Nav.Link>
              </LinkContainer> */}
              {account != null && account != '' ? (
                <LinkContainer to='/my-assets'>
                  <Nav.Link>My Digital Assets</Nav.Link>
                </LinkContainer>
              ) : null}
              {/*{account != null && account != '' ? (
              <LinkContainer to='/staking'>
                <Nav.Link>Staking</Nav.Link>
              </LinkContainer>
               ) : null}
                {account != null && account != '' ? (
              <LinkContainer to='/pool-holder'>
                <Nav.Link>Pool Holder</Nav.Link>
              </LinkContainer>
              ) : null}*/}
               {/*<NavDropdown title='Link' id='navbarScrollingDropdown'>
                <NavDropdown.Item href='#action3'>Action</NavDropdown.Item>
                <NavDropdown.Item href='#action4'>
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='#action5'>
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href='#' disabled>
                Link
              </Nav.Link>  */}
            </Nav>
            {/* <Form className='d-flex'>
              <FormControl
                type='search'
                placeholder='Search'
                className='me-2'
                aria-label='Search'
              />
              <Button variant='outline-success'>Search</Button>
            </Form> */}
            {!account ? (
              <button
                type='button'
                className='btn btn-primary btnConn'
                onClick={connectWallet}
              >
                {!isConnecting && 'Wallet Connect'}
                {isConnecting && 'Loading...'}
              </button>
            ) : (
              // <button type='button' className='btn btn-primary'>
              //   {shortenAddress(account)}
              // </button>
              <div style={{ display: 'flex' }}>
                <div className='hideMobile'>
                  <NotificationBar></NotificationBar>
                </div>
                {/*
                <Button
                  id='dropdown-basic-button'
                  title={shortenAddress(account)}
                  className='padd'
                  variant='primary'
                >
                  {shortenAddress(account)}
                </Button>
            */}
                <DropdownButton
                  id='dropdown-basic-button'
                  title={shortenAddress(account)}
                  className='padd'
                >
                  <Dropdown.Item
                    onClick={() => handleNetworkDisconnect()}
                  >
                    Log out
                  </Dropdown.Item>
                </DropdownButton>
                {/* <DropdownButton
                  id='dropdown-basic-button'
                  title={shortenAddress(account)}
                  className='padd'
                >
                  <Dropdown.Item
                    onClick={() => handleNetworkSwitch('avalanche')}
                  >
                    Switch To Avalanche
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleNetworkDisconnect('avalanche')}
                  >
                    Disconnect
                  </Dropdown.Item> */}
                {/* <Dropdown.Item
                  href='#/action-1'
                  onClick={() => handleNetworkSwitch('polygon')}
                >
                  Switch To Polygon
                </Dropdown.Item>
                <Dropdown.Item
                  href='#/action-2'
                  onClick={() => handleNetworkSwitch('bsc')}
                >
                  Switch To BSC
                </Dropdown.Item> */}
                {/* </DropdownButton> */}
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        className='Imetamask'
        show={isMetamaskAlertOpened}
        onHide={(e) => {
          setIsMetamaskAlertOpened(false)
        }}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          To keep your O2B's safe use metamask wallet
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={(e) => {
              setIsMetamaskAlertOpened(false)
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </header>
  )
}

export default Header
