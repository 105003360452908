import { ethers } from 'ethers'
import React from 'react'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useRecoilState } from 'recoil'
import { accountState } from '../services/atoms'

export default function ReferralModal(props) {

    const [account, setAccount] = useRecoilState(accountState)
    const [inputAddress, setInputAddress] = useState("")
    const [showInvalidAddressMessage,setShowInvalidAddressMessage] = useState(false)
    const [showSameAddressError,setShowSameAddressError] = useState(false)

    const handleClose = () => {
        setInputAddress("");
        showInvalidAddressMessage(false)
        setShowSameAddressError(false)
    }

    const handleAddressChanged = (event) => {
        setInputAddress(event.target.value);
        if(event.target.value !== ''){
            setShowInvalidAddressMessage(!ethers.utils.isAddress(event.target.value));
            if(event.target.value.toUpperCase() === account.toUpperCase()){
                setShowSameAddressError(true)
            }else{
                setShowSameAddressError(false)
            }
        }else{
            setShowSameAddressError(false)
        }
    }


    return (
        <>
            <Modal
                className='Imetamask pend'
                show={props.opened}
                onHide={(e) => {
                    //setPurchaseErrorMessage('')
                }}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header>Referral program</Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div>Do you have a referral code?</div>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Use it here for other benefits</Form.Label>
                        <input
                            className='form-control'
                            type="text"
                            placeholder="Must be a valid ethereum address"
                            value={inputAddress}
                            onChange={handleAddressChanged}
                        />
                        {showInvalidAddressMessage? <div>Invalid ethereum address</div> : null }
                        {showSameAddressError? <div>Address must not be the same used for the connection</div> : null }
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={(e) => {
                            props.onCancel()
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => {
                            props.onIgnoreAndContinue()
                            handleClose();
                        }}
                    >
                        Ignore and continue
                    </Button>
                    <Button
                        disabled={showSameAddressError || inputAddress ==="" || inputAddress === null || showInvalidAddressMessage}
                        onClick={(e) => {
                            props.onUseRefferalAndContinue(inputAddress)
                            handleClose();
                        }}
                    >
                        Use referral and continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
