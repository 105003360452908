import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button, Spinner } from 'react-bootstrap'
import { useRecoilState } from 'recoil';
import { AppParams, TokensClient } from '../config';
import { StakingContractAbi } from '../artifacts/contracts-abis/Staking-abi';
import { ethers } from 'ethers';
import { accountState, refreshIndex } from '../services/atoms';
import { myTokenDetails, myTokens, myTokensCategoryContains } from '../services/graphql';
import axios from 'axios';
import { MainTokenAbi } from '../artifacts/contracts-abis/MainToken-abi';
import { useInterval } from 'usehooks-ts';
import PoolHolder from './PoolHolder';
import { Link } from 'react-router-dom';
import StakingEquipement from '../components/StakingEquipement';
import { TokenImage } from '../components/TokenImage';
import ClaimButton from '../components/ClaimButton';
import { WalletHoldersAbi } from '../artifacts/contracts-abis/WalletHolders-abi';


const Staking = () => {

  const [open, setOpen] = React.useState(false);
  const [selectedToken, setSelectedToken] = React.useState(null);
  const [openDepositAnt, setOpenDepositAnt] = React.useState(false);
  const [pendingRewards, setPendingRewards] = React.useState(0);

  const [poolHoldersPendingRewards, setPoolHoldersPendingRewards] = React.useState(null);
  const [poolParticipation, setPoolParticipation] = React.useState(0);

  const [account, setAccount] = useRecoilState(accountState)
  const [refresh, setRefresh] = useRecoilState(refreshIndex);
  const [pendings, setPendings] = useState([])
  const [approving, setApproving] = useState(null)

  const [unstakedNftsList, setUnstakedNftsList] = useState({ data: [] })
  const [stakedNftsList, setStakedNftsList] = useState([])
  const [stakedNftsLoading, setStakedNftsLoading] = useState([])
  const [unstakedNftsLoading, setUnstakedNftsLoading] = useState([])
  const handleClose = () => {
    setOpen(false);
    setSelectedToken(null)
  }

  const handleOpen = (nft) => {

    setSelectedToken(nft)
  }

  useEffect(() => {

    if (selectedToken?.tokenId > 0) {
      setOpen(true);
    }
  }, [selectedToken])


  useInterval(() => {
    verifyStakingElements()
  }, 5000)

  const verifyStakingElements = () => {
    pendings.map((element) => verifyStakingElement(element.tokenId))
  }

  const verifyStakingElement = (tokenId) => {
    return TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: AppParams.MAIN_TOKEN_CONTRACT_ADDRESS.toLocaleLowerCase(),
        tokenId: parseInt(tokenId),
      },
    }).then((res) => {
      let owner = res.data.tokens[0].owner.id
      if (owner == AppParams.STAKING_CONTRACT_ADDRESS) {
        let items = pendings.filter((el) => el.tokenId !== tokenId)
        setPendings(items)
        setRefresh(refresh + 1)
      }
    })
  }

  /*useEffect(() => {
    let pendingString = JSON.stringify(pendings)
    localStorage.setItem('pendingStakingTransactions', pendingString)
  }, [pendings])*/

  const getStakedTokens = () => {
    setStakedNftsLoading(true);
    const provider = new ethers.providers.JsonRpcProvider(AppParams.RPC_URL)
    const stakingContract = new ethers.Contract(
      AppParams.STAKING_CONTRACT_ADDRESS,
      StakingContractAbi,
      provider
    )
    stakingContract.getUserStakedAntIds(account).then(async (data) => {
      let nfts = []
      for (var i = 0; i < data.length; i++) {
        let res = await TokensClient.query({
          query: myTokenDetails,
          fetchPolicy: 'network-only',
          variables: {
            contractAddress: AppParams.MAIN_TOKEN_CONTRACT_ADDRESS.toLocaleLowerCase(),
            tokenId: parseInt(data[i]),
          }
        })

        let item = {
          tokenId: res.data.tokens[0].tokenId,
          owner: res.data.tokens[0].owner.id,
          expirable: res.data.tokens[0].expirable,
          expireIn: res.data.tokens[0].expireIn,
          category: res.data.tokens[0].tokenClass,
          multiplier: res.data.tokens[0].multiplier > 0 ? (res.data.tokens[0].multiplier / (10 ** 6)) : 0,
          tokenYield: res.data.tokens[0].tokenYield != null ? ethers.utils.formatUnits(res.data.tokens[0].tokenYield, 'ether') : 0
        }

        let stakedAntsInfos = await stakingContract.stakedAnts(data[i]);


        let meta = await axios.get(res.data.tokens[0].tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY))
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          description: meta.data.description,
        }
        item["tokensMeta"] = tokensMeta;
        item["stakedAntsInfos"] = stakedAntsInfos;

        nfts.push(item);

      };
      setStakedNftsList(nfts)
      setStakedNftsLoading(false)

    })
  }

  const getUnstakedTokens = (query) => {
    const queryCall = myTokensCategoryContains;
    return TokensClient.query({
      query: queryCall,
      fetchPolicy: 'network-only',
      variables: {
        account: query.account,
        category: query.category,
        filter: query.filter,
        offset: query.page * query.pageSize,
        limit: query.pageSize,
        orderBy: query.orderBy === undefined ? "id" : query.orderBy,
        orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "desc" : query.orderDirection
      }
    }).then((res) => {
      setUnstakedNftsList({ data: res.data.tokens })
    })
  }

  useEffect(() => {

    let pendingString = localStorage.getItem('pendingStakingTransactions')
    if (pendingString != null) {
      let temp = JSON.parse(pendingString)
      setPendings(temp)
    }

    getUserPendingRewards();
    getStakedTokens();
    return () => {

    }
  }, [])
  useEffect(() => {

    getUnstakedTokens(
      {
        account: account,
        category: 'O2B',
        filter: '',
        page: 0,
        pageSize: 999,
        orderBy: 'id',
        orderDirection: 'desc'
      }
    );

    getUserPendingRewards();
    getStakedTokens();

    fetchHolderFeesPendingReward(account)
    fetchPoolParticipation(account)

    return () => {

    }
  }, [account, refresh])

  const fetchHolderFeesPendingReward = async (account) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const walletHoldersContract = new ethers.Contract(
      AppParams.WALLET_HOLDERS_CONTRACT_ADDRESS,
      WalletHoldersAbi,
      provider
    )
    const result = await walletHoldersContract.rewardsOwing(account)
    const accounts = await walletHoldersContract.accounts(account)
    console.log("accounts history balance", ethers.utils.formatUnits(accounts[0], 'ether'))
    setPoolHoldersPendingRewards(parseFloat(ethers.utils.formatUnits(accounts[0], 'ether')) + parseFloat(ethers.utils.formatUnits(result, 'ether')));


  }

  const fetchPoolParticipation = async (account) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const stakingContract = new ethers.Contract(
      AppParams.STAKING_CONTRACT_ADDRESS,
      StakingContractAbi,
      provider
    )

    const userInfos = await stakingContract.userInfos(account);
    const totalPoolValue = await stakingContract.totalPoolValue();
    setPoolParticipation((parseInt(userInfos[4]) / totalPoolValue) * 100);
  }

  const getUserPendingRewards = () => {
    const provider = new ethers.providers.JsonRpcProvider(AppParams.RPC_URL)
    const stakingContract = new ethers.Contract(
      AppParams.STAKING_CONTRACT_ADDRESS,
      StakingContractAbi,
      provider
    )
    stakingContract.getUserPendingRewards(account).then(pending => {
      setPendingRewards(ethers.utils.formatEther(pending));
    })
  }



  const depositMainToken = async (tokenId) => {

    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const signer = provider.getSigner()

    const mainTokenContract = new ethers.Contract(
      AppParams.MAIN_TOKEN_CONTRACT_ADDRESS,
      MainTokenAbi,
      signer
    )
    try {

      const approved = await mainTokenContract.getApproved(
        tokenId
      )

      console.log("O2B token approved", tokenId, approved)
      if (approved.toLocaleLowerCase() !== AppParams.STAKING_CONTRACT_ADDRESS.toLocaleLowerCase()) {
        setApproving(tokenId)
        const approveTransaction = await mainTokenContract.approve(
          AppParams.STAKING_CONTRACT_ADDRESS,
          tokenId
        )
        await approveTransaction.wait()
      } else {
        setApproving(null);
      }

      const stakingContract = new ethers.Contract(
        AppParams.STAKING_CONTRACT_ADDRESS,
        StakingContractAbi,
        signer
      )


      const transaction = await stakingContract.stakeAnt(tokenId)
      setApproving(null)

      let temp = [...pendings]
      temp.push({
        txId: transaction.hash,
        tokenId: tokenId,
        finished: false,
      })

      setPendings(temp)

      let txResult = await transaction.wait()
      setOpenDepositAnt(false)
      setRefresh(refresh + 1)



    } catch (e) {
      setApproving(null)
      throw new Error(e)
    }



  }

  const withdrawMainToken = async (tokenId) => {

    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const signer = provider.getSigner()



    const stakingContract = new ethers.Contract(
      AppParams.STAKING_CONTRACT_ADDRESS,
      StakingContractAbi,
      signer
    )
    const transaction = await stakingContract.withdrawAnt(tokenId)

    let txResult = await transaction.wait()
    setPendings(pendings.filter((el) => el.tokenId !== tokenId))
    setRefresh(refresh + 1)

  }

  return (
    <div className="container sectiontoken Cstaking">

      <div className="row centerS">
        <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12 bgB bgBA">

          <div className="alignSt">
            <div><h2 className="w-700" style={{ marginBottom: 0 }}>O2B <span style={{ color: '#6DFFA7' }}>Earned</span></h2></div>
            <ANTProgress value="50%" text={parseFloat(pendingRewards).toFixed(4) + ""} />
            <ClaimButton pendingRewards={pendingRewards} claimSuccess={getUserPendingRewards}></ClaimButton>
          </div>

          <div>
            <h2 className="w-700">NFT <span style={{ color: '#6DFFA7' }}>Staked</span></h2>
          </div>
          {
            stakedNftsLoading ?
              <div style={{ display: 'flex', justifyContent: ' center' }}>
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                <div><h5 style={{ color: '#6DFFA7' }}>Loading items</h5></div>
              </div>
              :

              <>
                <div className="tabSc">
                  <table className="tabStak">
                    <tbody>
                      {
                        stakedNftsList.map((nft, index) => (
                          <tr className="alignt" key={nft.tokenId}>
                            <td>#{nft.tokenId}</td>
                            <td><img src={nft.tokensMeta.image} width="45" style={{ borderRadius: "50%" }} /></td>
                            <td>{nft.tokenYield} O2B / DAY</td>

                            <td>
                              <div className="MobileP">

                                <button onClick={() => { handleOpen(nft) }} style={{ marginRight: 10 }}>
                                  {parseInt(nft.stakedAntsInfos.assetTokenId) > 0 ?
                                    <i className="fa-solid fa-wand-magic-sparkles"></i>
                                    :
                                    <i style={{ color: '#686565' }} className="fa-solid fa-wand-magic-sparkles disabledIcon"></i>
                                  }
                                  APY boosters</button><button onClick={() => { withdrawMainToken(nft.tokenId) }}>Withdraw</button>
                              </div>
                            </td>



                          </tr>

                        ))
                      }
                    </tbody>
                  </table>

                </div>

                {
                  stakedNftsList.length == 0 ? <div><h5 style={{ color: '#6DFFA7' }}>It's the right time to stake your first NFT and get rewards</h5></div>
                    : null
                }
              </>

          }


          <StakingEquipement opened={open} close={handleClose} mainToken={selectedToken} ></StakingEquipement>



          <div><button className="btnClaim" style={{ fontSize: 22, minWidth: 150 }} onClick={() => { setOpenDepositAnt(true) }} >Deposit</button></div>

          <Modal
            show={openDepositAnt}
            className="modalDeposit"
            onHide={(e) => { setOpenDepositAnt(false) }}
          >
            <Modal.Header style={{justifyContent:'flex-end'}}>
              

              <span
                variant='secondary'
                onClick={(e) => { setOpenDepositAnt(false) }}
                style={{ fontSize: 20, cursor: 'pointer' }}
              >
                x
              </span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
            <h4 style={{margin:'0.5em' , marginBottom:30}}>
                O2B NFT
              </h4>

              {
                unstakedNftsLoading == true ?
                  <div style={{ display: 'flex', justifyContent: ' center !important' }}>
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                    <div><h5 style={{ color: '#6DFFA7' }}>Loading items</h5></div>
                  </div>
                  :
                  unstakedNftsList.data.length > 0 ?
                    <table className="tabStak">
                      <tbody>{
                        unstakedNftsList.data.map((nft, index) => (

                          stakedNftsList.filter((stakedElement) => stakedElement.tokenId === nft.tokenId).length > 0 ? null :


                            <tr key={nft.tokenId}>
                              <td>#{nft.tokenId}</td>
                              <td><TokenImage value={nft.tokenURI}></TokenImage></td>
                              <td>{parseFloat((nft.tokenYield > 0 ? ethers.utils.formatUnits(nft.tokenYield, 'ether') : 0)).toLocaleString().replace(",", ".")} O2B / DAY</td>
                              <td>
                                {(pendings.filter((el) => el.tokenId === nft.tokenId).length > 0) || approving == nft.tokenId ? (
                                  <button className='buy' variant='primary'>
                                    <Spinner
                                      as='span'
                                      animation='grow'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
                                    {approving == nft.tokenId ? 'Approving...' : 'Pending...'}
                                  </button>
                                ) :
                                  <button disabled={approving != null} onClick={() => { depositMainToken(nft.tokenId) }}>Deposit</button>
                                }
                              </td>

                            </tr>
                        ))
                      }</tbody>
                    </table>
                    :
                    <div style={{ height: '150px', lineHeight: '150px', textAlign: 'center' }}>
                      <span style={{ verticalAlign: 'middle', color: '#fff', fontSize: 18 }}>No assets yet. Get your first NFT from <Link to="/nfts?category=ANT1" style={{ color: "#6dffa7" }}>here</Link></span>
                    </div>
              }

            </Modal.Body>
            <Modal.Footer style={{ alignSelf: 'center' }}>


            </Modal.Footer>

          </Modal>


        </div>
      </div>
      <div className="row centerS">
        <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12 bgB bgBA">
          <div><h4 className="w-700" style={{ marginBottom: 0, color: '#fff' }}>Pool participation : <span style={{ color: '#0fff17' }}>{poolParticipation == null ? '--' : poolParticipation.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).replace(",", ".")}%</span></h4></div>
          <div><h4 className="w-700" style={{ marginBottom: 0, color: '#fff' }}>Rewards in pending : <span style={{ color: '#0fff17' }}>{poolHoldersPendingRewards == null ? '--' : poolHoldersPendingRewards.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }).replace(",", ".")} O2B</span></h4></div>
          <div><button className="btnClaim"><Link to='/pool-holder' style={{ color: "#14142e" }}>View Details</Link></button></div>



        </div>
      </div>
    </div>

  )
}
export function ANTProgress({ value, text, showNumber, size, wid, active, currentLevel }) {
  return (
    <div className="ANTProgress" style={{ display: 'contents' }}>

      {showNumber ? (
        <div className='ANTProgress-numbers'>
          {[...(new Array(showNumber))].map((i, num) => <span style={{ backgroundColor: ((currentLevel - 1) < num ? '' : '#6DFFA7'), color: ((currentLevel - 1) < num ? '' : '#00001E') }} key={num}>{num + 1}</span>)}
        </div>
      ) : null}

      <div className="stackA" style={{ height: size || "51px", width: wid || "50%" }}>


        <div className="left"></div>
        <div className="stack-value test" style={{ width: value }}>    <button><span>{text}<img src="/images/logo-ant2.svg" height="40" alt="logo ant" /></span></button></div>

        <div className="right"></div>
      </div>

    </div>


  );
}


export default Staking

