import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Form, Button } from 'react-bootstrap'
// import Rating from '../components/Rating'
//import products from '../products'
import { ethers } from 'ethers'
import axios from 'axios'

import { AppParams, TokensClient } from '../config'

import { myTokenDetails } from '../services/graphql'
import { getImage } from '../services/imageService'


const MyAssetDetails = () => {
  const { token_id, contractAddress } = useParams()
  const navigate = useNavigate()
  const [nft, setNft] = useState({ category: '--' })

  const [item, setItem] = useState({
    image: ("/images/Spin-det.svg"),
    name: "--",
    description: "--",
    tokenURI: "--",
    pdfFile: '--',
  })

  useEffect(() => {
    // Load NFTS
    loadNFTs()
  }, [])

  async function loadNFTs() {

    TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: contractAddress,
        tokenId: parseInt(token_id),
      }
    }).then((res) => {
      let item = {
        owner: res.data.tokens[0].owner.id,
        category: res.data.tokens[0].tokenClass,
      }
      setNft(item)

      axios.get(res.data.tokens[0].tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then(meta => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          description: meta.data.description,
          tokenURI: res.data.tokens[0].tokenURI,
          pdfFile: meta.data.pdfFile != null ? meta.data.pdfFile : "--",
        }
        setItem(tokensMeta);
      })

    })
  }

  function showOnExplorer(event) {
    event.stopPropagation();
    window.open(`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`, "_blanc")
  }

  function downloadURI(uri, tokenClass) {

    fetch(uri)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = tokenClass + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })

  }

  return (
    <div className='screenD container'>
      <Link className='btn btn-outline-primary my-2 pl-0' to='/my-assets'>
        <i className='fa-solid fa-angle-left'></i> Back
      </Link>
      <Row>
        <Col>
          <span className='codeD' style={{ cursor: 'pointer' }} onClick={showOnExplorer}>#{token_id}</span>
        </Col>
      </Row>
      <Card.Text as='h3'>{nft?.name}</Card.Text>
      <Row className="rowD">
        <Col md={6} style={{zIndex:1}}>
          <div className="p25">

            <Image src={getImage(nft?.category)} alt={item?.name} style={{ borderRadius: 20 }} fluid />


          </div>
        
        
              {/*<div style={{ textAlign: 'center' }}>
                <Button className='download-pdf m-3' variant='primary' onClick={(e) => { downloadURI(item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY), nft?.category) }}>
                  Download PDF
                </Button>
              </div>*/}
            
           
        </Col>
        <Col md={6} className="colS">
          
            <div>
              <h4>About</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item className="priceD">
                  Contract <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{contractAddress}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  Token ID <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{token_id}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  Owner <a className="itemS" href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${nft.owner}`} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{nft?.owner == null ? "--" : nft?.owner}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  Class <span>{nft?.category}</span>{' '}
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                Token URI <a className="itemS" href={item?.tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{item?.tokenURI}</span></a>
              </ListGroup.Item>
              <ListGroup.Item className="priceD" data-truncate="on ad">
                Project info <a className="itemS" href={item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY)} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{item?.pdfFile}</span></a>

              </ListGroup.Item>


              </ListGroup>
            </div>

         
        </Col>
      </Row>
      <Row>
        <Col>
        <div>
            <h4>More</h4>
            <ListGroup variant='flush' className='card cardab'>
        <ListGroup.Item>
                <div style={{ textAlign: 'center' }}> Description</div><br />  <div className="spandesc"><span>{item?.description}</span></div>{' '}
              </ListGroup.Item>
              </ListGroup>

</div>
              </Col>
      </Row>
    </div>
  )
}

export default MyAssetDetails
