import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import NFTDetails from './screens/NFTDetails'
import Nfts from './screens/Nfts'
import CreateItem from './screens/CreateItem'
import MyAssets from './screens/MyAssets'
import CreatorDashboard from './screens/CreatorDashboard'
import CreateAsset from './screens/CreateAsset'
import MyAssetDetails from './screens/MyAssetDetails'
import BatchCreateItems from './screens/BatchCreateItems'
import Staking from './screens/Staking'
import PoolHolder from './screens/PoolHolder'
import BatchCreateEquipements from './screens/BatchCreateEquipements'
import BatchCreateReducer from './screens/BatchCreateReducer'

const App = () => {
  return (
    <Router>
   
      <>
     
        <Header />
        <main>
      
  
          <Container fluid>
          <img src="https://assets.website-files.com/62d161b2bc864971218efada/62d697ea3195b82b517645fe_blob_2-p-1080.png" className="bgc1" /> 
      <img src="https://assets.website-files.com/62d161b2bc864971218efada/62d697ea3195b82b517645fe_blob_2-p-1080.png" className="bgc2" /> 

        
            <Routes>
           
              <Route path='/' element={<Nfts />} exact />
              <Route path='/product/:id' element={<ProductScreen />} />
              <Route
                path='/nft/:token_id/:item_id/:contractAddress'
                element={<NFTDetails />}
              />
              <Route path='/nfts' element={<Nfts />} />
              <Route path='create-item' element={<CreateItem />} />
              <Route path='batch-create-item' element={<BatchCreateItems />} />
              <Route path='batch-create-equipement' element={<BatchCreateEquipements />} />
              <Route path='batch-create-reducers' element={<BatchCreateReducer />} />
              <Route path='create-asset' element={<CreateAsset />} />
              <Route path='creator-dashboard' element={<CreatorDashboard />} />
              <Route
                path='/my-assets/:token_id/:contractAddress'
                element={<MyAssetDetails />}
              />
              <Route path='my-assets' element={<MyAssets />} />
              {/*
                <Route path='staking' element={<Staking />} />
                <Route path='pool-holder' element={<PoolHolder />} />
              */}
            </Routes>
          </Container>
      
        </main>
        <Footer />
      </>
    </Router>
  )
}

export default App
