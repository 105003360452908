import { atom } from 'recoil'

export const accountState = atom({
  key: 'accountState',
  default: '',
})

export const pendingTransactions = atom({
  key: 'pendingTransactions',
  default: [],
})

export const refreshIndex = atom({
  key: 'refreshIndex',
  default: 0,
})
export const purchaseEnd = atom({
  key: 'purchaseEnd',
  default: false,
})

export const mainTokenTotalForSale = atom({
  key: 'mainTokenTotalForSale',
  default: 0,
})
export const assetTokenTotalForSale = atom({
  key: 'assetTokenTotalForSale',
  default: 0,
})

export const convertPriceUsdAvax = atom({
  key: 'convertPriceUsdAvax',
  default: 0,
})

export const avaxValueUsd = atom({
  key: 'avaxValueUsd',
  default: 0,
})

export const feesReducerActivatedToken = atom({
  key: 'feesReducerActivatedToken',
  default: 0,
})
export const feesReducerActivatedTokenRate = atom({
  key: 'feesReducerActivatedTokenRate',
  default: 0,
})
export const referralAddressState = atom({
  key: 'referralAddressState',
  default: null,
})
